<script setup>
import { ref, computed } from 'vue';
import PrimaryButton from "@/Components/PrimaryButton.vue";
import TextInput from "@/Components/TextInput.vue";
import AppLayout from "@/Layouts/AppLayout.vue";
import Event from "@/Components/Dashboard/Event.vue";

const props = defineProps({
    federations: {
        type: Array,
        default: []
    },
    texte: {
        type: Object,
        default: {}
    },
    imagini: {
        type: Object,
        default: {}
    }
});

const searchQuery = ref('');

// Computed property to filter federations based on the search query
const filteredFederations = computed(() => {
    if (!searchQuery.value) return props.federations;
    return props.federations.filter(federation =>
        federation.name.toLowerCase().includes(searchQuery.value.toLowerCase())
    );
});

// State to track which federation's accordion is expanded
const expandedFederationId = ref(null);

// Toggle accordion for a federation
const toggleAccordion = (federationId) => {
    expandedFederationId.value = (expandedFederationId.value === federationId) ? null : federationId;
};

const showPlaceholderImg = (event) => {
    // change this into a <i> tag with a placeholder icon from font-awesome
    let e = event.target;
    let i = document.createElement('i');
    i.classList.add('fal', 'fa-image', 'text-gray-300', 'text-6xl');
    e.replaceWith(i);

}
</script>


<template>
  <div class="home min-h-screen bg-gray-900 text-white">
  <!-- Header -->
  <!-- Navbar -->
    <header class="flex justify-between items-center p-6">
      <img class="h-12 w-auto" src="/img/logo.png" alt="Romania Be Active" />
      <nav class="space-x-12 hidden md:flex navbar">
        <a href="#beactive" class="hover:text-green-400 text-lg">Romania Be Active 2024</a>
        <a href="#despre" class="hover:text-green-400 text-lg">Despre app</a>
        <a href="#parteneri" class="hover:text-green-400 text-lg">Parteneri</a>
        <a href="#unde" class="hover:text-green-400 text-lg">Unde</a>
      </nav>
      <a
        :href="route('login')"
        class="bg-transparent border border-green-400 text-green-400 py-2 px-4 hover:bg-green-400 hover:text-gray-900 text-lg"
        >Intră in app</a
      >
    </header>

    <!-- Hero Section -->
    <section
      class="flex flex-col justify-center items-center text-center space-y-12 py-24 px-16 bg-cover bg-center"
      :style="'background-image: url('+props.imagini['hero image']+')'" id="despre"
    >
        <h1 class="text-4xl md:text-5xl lg:text-6xl font-extrabold text-green-500 primary">
            {{props.texte['Hero Title']}}
        </h1>
        <div class="text-hero">
          <p class="text-lg md:text-xl pr-8 pl-6">
            {{props.texte['Hero Text']}}
          </p>
        </div>
        <div class="space-x-4">
          <a href="/register">
            <button
              class="bg-green-500 text-gray-900 font-bold py-3 px-8 rounded-md hover:bg-green-600 uppercase primary-background"
            >
              Vreau pașaport
            </button>
          </a>
          <a href="#federatii">
            <button
              class="bg-transparent border border-green-500 text-green-500 font-bold py-3 px-8 rounded-md hover:bg-green-500 hover:text-gray-900 uppercase"
            >
              Vezi activități
            </button>
          </a>
        </div>
    </section>

    <section id="beactive">


      <div class="bg-white py-12 px-6">
        <div class="max-w-7xl mx-auto text-center">
          <!-- Heading -->
          <h2 class="text-3xl md:text-4xl font-extrabold text-gray-800">
              {{props.texte['Heading']}}
          </h2>
          <!-- Paragraph -->
          <p class="mt-4 text-lg md:text-xl text-gray-600">
                {{props.texte['Text Heading']}}
          </p>
        </div>
      </div>
    </section>

    <section class="bg-gray-100 py-16 px-4">
      <div class="max-w-7xl mx-auto grid grid-cols-1 md:grid-cols-2 gap-8">

        <!-- Trainers Block -->
        <div class="flex bg-white rounded-lg shadow-lg overflow-hidden">
          <img
            class="w-1/2 object-cover"
            :src="props.imagini['imagine stanga']"
            alt="Trainer and client"
          />
          <div class="w-1/2 p-8 bg-green-100">
            <h3 class="text-2xl font-bold text-gray-800">Activități</h3>
            <p class="text-gray-700 mt-4">
              {{ props.texte['Box stanga Text'] }}
            </p>
          </div>
        </div>

        <!-- Members Block -->
        <div class="flex bg-white rounded-lg shadow-lg overflow-hidden">
          <img
            class="w-1/2 object-cover"
            :src="props.imagini['imagine dreapta']"
            alt="Members working out"
          />
          <div class="w-1/2 p-8 bg-green-100">
            <h3 class="text-2xl font-bold text-gray-800">{{props.texte['Box dreapta titlu']}}</h3>
            <p class="text-gray-700 mt-4">
                {{props.texte['Box dreapta text']}}
            </p>

          </div>
        </div>

      </div>
    </section>

    <section class="bg-white" id="parteneri">

    <h2 class="font-semibold text-xl leading-tight bg-transparent">
        Lista Partenerilor
    </h2>


      <div>
          <div class="max-w-7xl mx-auto sm:px-4 lg:px-4 py-6">
              <div class="bg-white overflow-hidden shadow-lg sm:rounded-lg p-6">
                  <div>
                      <h3 class="text-lg font-semibold mb-4">Lista Partenerilor</h3>

                      <!-- Search Input -->
                      <div class="mb-6">
                          <TextInput
                              v-model="searchQuery"
                              placeholder="Caută parteneri..."
                              class="w-full text-gray-700"
                          />
                      </div>

                      <!-- Federation List with Accordion -->
                      <div v-if="filteredFederations.length" class="max-h-[660px] overflow-y-auto">
                          <div
                              v-for="federation in filteredFederations"
                              :key="federation.id"
                              class="mb-4 p-4 bg-gray-100 rounded-xl cursor-pointer"
                          >
                              <!-- Federation Header -->
                              <div class="flex items-center justify-between"
                                  @click="toggleAccordion(federation.id)">
                                  <div class="flex items-center">
                                      <!-- Federation Logo -->
                                      <div class="mr-8">
                                          <img v-if="federation.profile_photo_path" :src="'/storage/' + federation.profile_photo_path" alt="Logo"
                                              class="h-16 w-16 rounded-full object-cover"/>
                                          <div v-else
                                              class="h-16 w-16 bg-gray-300 rounded-full flex items-center justify-center text-gray-600">
                                              <span>{{ federation.name.charAt(0) }}</span>
                                          </div>
                                      </div>

                                      <!-- Federation Info -->
                                      <div>
                                          <p class="font-semibold text-lg text-gray-700">{{ federation.name }}</p>
                                      </div>
                                  </div>

                                  <!-- Expand/Collapse Icon -->
                                  <div>
                                      <i v-if="expandedFederationId === federation.id"
                                        class="fas fa-chevron-up"></i>
                                      <i v-else class="fas fa-chevron-down text-gray-700"></i>
                                  </div>
                              </div>

                              <!-- Accordion Content: Federation's Events -->
                              <div v-if="expandedFederationId === federation.id"
                                  class="mt-4 bg-gray-50 p-4 rounded-lg">
                                  <h4 class="font-semibold text-md mb-2 text-black">Activități</h4>
                                  <div v-if="federation.events && federation.events.length" >
                                      <div v-for="event in federation.events" class="flex items-center py-4">
                                          <div class="mr-8 flex justify-center h-full items-center">
                                              <div class="min-w-32 text-center">
                                                  <img v-if="event.image" @error="showPlaceholderImg($event)" class="max-h-32 max-w-32"
                                                      :src="'/storage/' + event.image" alt="Event Image"/>
                                                  <i v-else class="fal fa-image text-gray-300 text-6xl"></i>
                                              </div>
                                          </div>
                                          <div>
                                              <p class="font-semibold text-gray-700">{{ event.name }}</p>
                                              <p class="text-sm text-gray-400 py-3"
                                                v-html="event.description"></p>
                                              <div class="flex">
                                                  <p class="text-sm text-gray-600 mr-8">
                                                      <i class="fal fa-people w-6"></i>
                                                      {{ event.participant_count }}
                                                  </p>
                                                  <p class="text-sm text-gray-600">
                                                      <i class="fal fa-calendar w-6"></i>
                                                      {{ $moment(event.date_start).format('DD MMM YYYY HH:mm') }}
                                                  </p>
                                              </div>
                                          </div>
                                      </div>
                                  </div>
                                  <div v-else>
                                      <p class="text-gray-500">Nu există activități disponibile.</p>
                                  </div>
                              </div>
                          </div>
                      </div>

                      <!-- No federations found -->
                      <div v-else>
                          <p class="text-gray-500">Nu s-au găsit parteneri.</p>
                      </div>
                  </div>
              </div>
          </div>
      </div>
    </section>

    <section id="unde" class="bg-white-100">
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2848.798874677599!2d26.149959712028245!3d44.43728770124616!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40b1f932b120b96b%3A0x53a7021f11d624a4!2zQXJlbmEgTmHIm2lvbmFsxIM!5e0!3m2!1sro!2sro!4v1726756124967!5m2!1sro!2sro"
        width="100%"
        height="400px"
        style="border:0;"
        allowfullscreen=""
        loading="lazy"
        referrerpolicy="no-referrer-when-downgrade"
      ></iframe>

    </section>


    <!-- Footer -->
    <footer class="bg-gray-800 text-white py-10 secondary-background">
      <div class="container mx-auto grid grid-cols-1 md:grid-cols-3 gap-8 max-w-7xl">

        <div class="logouri">
          <img src="/img/ce.png" alt="Logo 1" class="mb-4">
          <img src="/img/GOV1.png" alt="Logo 2">
        </div>


        <div>
          <ul>
            <li><a href="/terms-of-service" class="hover:underline">GDPR</a></li>
            <li><a href="/privacy-policy" class="hover:underline">Confidențialitatea datelor</a></li>
          </ul>
        </div>


        <div>
          <p>Telefon: +40 123 456 789</p>
          <p>Email: comunicare@sport.gov.ro</p>
          <p>Adresă: Str. Vasile Conta 16, București</p>
        </div>
      </div>
    </footer>

  </div>
</template>
